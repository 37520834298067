import { useState } from "react";
import { Container, chakra, Flex, Box, Text } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { useGetEssaysPath } from "@/hooks/useGetEssaysPath";
import Navbar from "@/components/Navbar";
import { GradientText, LinkButton } from "@/components/shared/generic";
import { DATABASE_SIZE } from "@/utils/profile";
import { SingleProductCarousel } from "../ProfileCarousels";
import { TrustedUsers } from "../TrustedUsers";
import { Testimonial } from "../Testimonials";
import { TypeAnimation } from "../../shared/animations/TypeAnimation";
import { FadeInOutText } from "@/components/shared/animations/FadeInOutText";

export const Hero = ({ previewProfiles, testimonials }) => {
  const essaysPath = useGetEssaysPath();

  const [nIterations, setNIterations] = useState(0);

  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    mobileBg: "#EC538A",
    display: "inline",
  };

  const isEssay = nIterations % 3 === 2 || nIterations % 4 === 3;

  return (
    <Box
      pos="relative"
      pb={40}
      background={
        "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/images/home/hero-bg.png')"
      }
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex direction="column">
        <Navbar inverted />

        <Container
          maxW="container.xl"
          mt={16}
          textAlign={{ base: "center", md: "left" }}
          color="white"
          paddingInlineStart={{ base: "2rem", "2xl": "1rem" }}
          paddingInlineEnd={{ base: "2rem", "2xl": "1rem" }}
        >
          <Flex justify={{ base: "center", md: "space-between" }}>
            <Box>
              <chakra.h1 fontWeight="700" textStyle="pageHeader" lineHeight="100%" mb={"1"}>
                <chakra.span fontSize="4rem">
                  Get into your <br /> dream school.
                </chakra.span>
              </chakra.h1>

              <Box minHeight={{ base: "6rem", sm: "3rem" }}>
                <Box
                  fontWeight="900"
                  lineHeight="1.2em"
                  fontSize="2.5em"
                  color="#EC538A"
                  {...(isEssay && { color: "#3CB2FD" })}
                >
                  <TypeAnimation
                    isTrue={isEssay}
                    text1={"Revise your essay in minutes"}
                    text2={"Read successful applications"}
                  />
                </Box>
              </Box>

              <Box height={"3rem"} my={2}>
                <FadeInOutText
                  fontSize="1rem"
                  maxW={"26rem"}
                  m={{ base: "0 auto", md: "0" }}
                  condition={isEssay}
                  text1={
                    "Get admissions-specific advice on how to take your essay to the next level and make a lasting impression."
                  }
                  text2={`Compare yourself to admits at top schools. Read the
                  essays, activities, and stats of ${DATABASE_SIZE.applications} applications.`}
                />
              </Box>

              <Flex mt={8} mb={4} gap={4} align="stretch" direction={{ base: "column", md: "row" }}>
                <LinkButton
                  color="black"
                  colorScheme="gray"
                  href="/profiles"
                  shadow={`-4px 4px 0px 0px #6A7684`}
                  rightIcon={<ArrowForwardIcon />}
                  width={{ base: "100%", md: "auto" }}
                >
                  Profiles
                </LinkButton>

                <LinkButton
                  colorScheme="transparent"
                  href={essaysPath}
                  shadow={`-4px 4px 0px 0px #FFF`}
                  rightIcon={<ArrowForwardIcon />}
                  border="1px solid #fff"
                  background="rgba(0, 0, 0, 0.46)"
                  width={{ base: "100%", md: "auto" }}
                >
                  Essays
                </LinkButton>
              </Flex>

              <Box>
                <LinkButton
                  variant="text"
                  href="/get-started/upload-your-applications"
                  p={0}
                  gap={2}
                  leftIcon={<Text fontSize="3xl">👨‍🎓</Text>}
                >
                  <chakra.span
                    color="white"
                    textDecoration="underline"
                    fontWeight="700"
                    fontSize="sm"
                  >
                    I AM A COLLEGE STUDENT
                  </chakra.span>
                </LinkButton>
              </Box>
            </Box>

            <Box
              display={{ base: "none", lg: "block" }}
              mt={2}
              style={{ width: "24rem" }}
              color="black"
            >
              <SingleProductCarousel
                profiles={previewProfiles}
                nIterations={nIterations}
                setNIterations={setNIterations}
                includeEssay
              />
            </Box>
          </Flex>

          <Flex
            mt={10}
            gap={16}
            flexDir={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "normal" }}
          >
            <TrustedUsers testimonials={testimonials} />

            <Flex gap={4} align="center">
              <GradientText
                {...textSuccessful}
                fontWeight="600"
                display="inline-block"
                fontSize="4xl"
              >
                91%
              </GradientText>

              <chakra.p fontSize="md">
                Success rate at <br /> top 50 schools
              </chakra.p>
            </Flex>
          </Flex>
        </Container>

        <Container
          maxW="container.xl"
          display="flex"
          flexDir={{ base: "column", md: "row" }}
          mt={{ base: 10, md: 40 }}
          gap={8}
          paddingInlineStart={{ base: "2rem", "2xl": "1rem" }}
          paddingInlineEnd={{ base: "2rem", "2xl": "1rem" }}
        >
          <Testimonial
            color="#fff"
            bg="linear-gradient(38deg, #18173C 17.64%, rgba(83, 22, 60, 0.88) 89.72%)"
            {...testimonials[0]}
          />

          <Testimonial
            color="#fff"
            bg="linear-gradient(38deg, #18173C 17.64%, rgba(83, 22, 60, 0.88) 89.72%)"
            {...testimonials[1]}
          />

          <Testimonial
            color="#fff"
            bg="linear-gradient(38deg, #18173C 17.64%, rgba(83, 22, 60, 0.88) 89.72%)"
            {...testimonials[2]}
          />
        </Container>
      </Flex>
    </Box>
  );
};
